import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import loadable from '@loadable/component'

const LoadComponent = loadable(() => import('../components/typeformEmbed'))

const MissionPlan1 = props => (
  <Layout footerShow="hide">
    <SEO title="Mission Plan #1" keywords={['sustainability', 'mission', 'plan']} />
    <LoadComponent id="c8LSIV" />
  </Layout>
)

export default MissionPlan1
